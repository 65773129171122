import React from "react"
import { Layout } from "@/layout"
import { Box, SEO, Heading, Text, Width, CTA } from "@/components/index"
import { color } from "@/theme/index"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import localStorage from "@/utils/localStorage"

export default function Invite() {
  const location = useLocation()
  const urlParameters =
    typeof window !== "undefined" ? queryString.parseUrl(location.search) : ""
  if (urlParameters.query?.rid) {
    localStorage.set("referrer_id", urlParameters.query.rid)
  }
  return (
    <Layout headerType="checkout" headerTheme="light" footerTheme="light">
      <SEO
        title="Accelerated Learning Module"
        description="Sonora Guitar Intensive"
        noindex={true}
      />
      <Box
        bg="agate"
        height="100%"
        position="relative"
        zIndex="3"
        top="0"
        width="100%"
        display="flex"
        flexDirection="column"
        textAlign="center"
        pt={"9.6rem"}
        pb={["4.8rem", null, null, "0"]}
      >
        <Heading level="1" mt="5rem" mb="2rem">
          Accelerated Learning Module
        </Heading>
        <Width
          display="flex"
          flexDirection={["column", "row"]}
          pt={["0", null, null, "2.2rem"]}
          pb={["0", null, null, "13.9rem"]}
        >
          <Box
            width={["100%", null, 9 / 12]}
            mx="auto"
            textAlign="center"
            mb={["6rem", null, null, "0"]}
            px={["2.4rem", "0"]}
          >
            <Text mb="2.3rem">
              This is the very first module in our program. It will teach you
              everything you need to know about deliberate practice, and accelerated
              learning.
            </Text>
            <Width display="flex" flexDirection={["column", "row"]}>
              <Box width={[12 / 12, null, null, 6 / 12]} mr={['0rem', null, '1rem']}>
                <Box
                  width={["100%", "80%", 12 / 12, null]}
                  p="2.4rem"
                  textAlign="center"
                  mb={"2.4rem"}
                  bg={color.opal}
                  borderRadius=".6rem"
                  ml={["auto", null, 0, null]}
                  mr={["auto", null, "1.8rem", null]}
                >
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Text fontWeight="bold" color="amethyst">
                      Part 1: Introduction
                    </Text>
                  </Box>
                  <Text level={2}>
                    Setting the stage for Accelerated Learning
                  </Text>
                  <CTA
                    to="/orientation/introduction"
                    variant="special"
                    mt="1.5rem"
                  >
                    Watch
                  </CTA>
                </Box>
                <Box
                  width={["100%", "80%", 12 / 12, null]}
                  p="2.4rem"
                  textAlign="center"
                  mb={"2.4rem"}
                  bg={color.opal}
                  borderRadius=".6rem"
                  ml={["auto", null, 0, null]}
                  mr={["auto", null, "1.8rem", null]}
                >
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Text fontWeight="bold" color="amethyst">
                      Part 2: Path Finding
                    </Text>
                  </Box>
                  <Text level={2}>
                    How to find the clearest path through any skill
                  </Text>
                  <CTA
                    to="/orientation/pathfinding"
                    variant="special"
                    mt="1.5rem"
                  >
                    Watch
                  </CTA>
                </Box>
                <Box
                  width={["100%", "80%", 12 / 12, null]}
                  p="2.4rem"
                  textAlign="center"
                  mb={"2.4rem"}
                  bg={color.opal}
                  borderRadius=".6rem"
                  ml={["auto", null, 0, null]}
                  mr={["auto", null, "1.8rem", null]}
                >
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Text fontWeight="bold" color="amethyst">
                      Part 3: The Sonora Path
                    </Text>
                  </Box>
                  <Text level={2}>How we think about the guitar path</Text>
                  <CTA
                    to="/orientation/sonora-path"
                    variant="special"
                    mt="1.5rem"
                  >
                    Watch
                  </CTA>
                </Box>
                <Box
                  width={["100%", "80%", 12 / 12, null]}
                  p="2.4rem"
                  textAlign="center"
                  mb={"2.4rem"}
                  bg={color.opal}
                  borderRadius=".6rem"
                  ml={["auto", null, 0, null]}
                  mr={["auto", null, "1.8rem", null]}
                >
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Text fontWeight="bold" color="amethyst">
                      Part 4: Deliberate Practice - Foundations
                    </Text>
                  </Box>
                  <Text level={2}>The Principles of Deliberate Practice</Text>
                  <CTA
                    to="/orientation/deliberate-foundations"
                    variant="special"
                    mt="1.5rem"
                  >
                    Watch
                  </CTA>
                </Box>
              </Box>
              <Box width={[12 / 12, null, null, 6 / 12]}>
                <Box
                  width={["100%", "80%", 12 / 12, null]}
                  p="2.4rem"
                  textAlign="center"
                  mb={"2.4rem"}
                  bg={color.opal}
                  borderRadius=".6rem"
                  ml={["auto", null, 0, null]}
                  mr={["auto", null, "1.8rem", null]}
                >
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Text fontWeight="bold" color="amethyst">
                      Part 5: Deliberate Practice - Applied
                    </Text>
                  </Box>
                  <Text level={2}>
                    Getting to the brass tacks
                  </Text>
                  <CTA
                    to="/orientation/deliberate-applied"
                    variant="special"
                    mt="1.5rem"
                  >
                    Watch
                  </CTA>
                </Box>
                <Box
                  width={["100%", "80%", 12 / 12, null]}
                  p="2.4rem"
                  textAlign="center"
                  mb={"2.4rem"}
                  bg={color.opal}
                  borderRadius=".6rem"
                  ml={["auto", null, 0, null]}
                  mr={["auto", null, "1.8rem", null]}
                >
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Text fontWeight="bold" color="amethyst">
                      Part 6: Lifecycle of a Skill - Absorption
                    </Text>
                  </Box>
                  <Text level={2}>How to absorb new content efficiently</Text>
                  <CTA
                    to="/orientation/los-absorption"
                    variant="special"
                    mt="1.5rem"
                  >
                    Watch
                  </CTA>
                </Box>
                <Box
                  width={["100%", "80%", 12 / 12, null]}
                  p="2.4rem"
                  textAlign="center"
                  mb={"2.4rem"}
                  bg={color.opal}
                  borderRadius=".6rem"
                  ml={["auto", null, 0, null]}
                  mr={["auto", null, "1.8rem", null]}
                >
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Text fontWeight="bold" color="amethyst">
                      Part 7: Lifecycle of a Skill - Integration + Retention
                    </Text>
                  </Box>
                  <Text level={2}>How to polish and retain new skills</Text>
                  <CTA
                    to="/orientation/los-integration"
                    variant="special"
                    mt="1.5rem"
                  >
                    Watch
                  </CTA>
                </Box>
                <Box
                  width={["100%", "80%", 12 / 12, null]}
                  p="2.4rem"
                  textAlign="center"
                  mb={"2.4rem"}
                  bg={color.opal}
                  borderRadius=".6rem"
                  ml={["auto", null, 0, null]}
                  mr={["auto", null, "1.8rem", null]}
                >
                  <Box
                    display="flex"
                    mb="0.6rem"
                    alignItems="center"
                    justifyContent={"center"}
                  >
                    <Text fontWeight="bold" color="amethyst">
                      Part 8: How to Practice
                    </Text>
                  </Box>
                  <Text level={2}>An example of an ideal practice session</Text>
                  <CTA
                    to="/orientation/how-to-practice"
                    variant="special"
                    mt="1.5rem"
                  >
                    Watch
                  </CTA>
                </Box>
              </Box>
            </Width>
          </Box>
        </Width>
      </Box>
    </Layout>
  )
}
